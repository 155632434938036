<template>
  <div>
    <el-row>
      <el-input
        @keyup.enter.native="scanOrderChange"
        v-model="codeValue"
        style="width: 40%"
        size="small"
        placeholder="扫描二维码前请用鼠标点击此框,并将输入法切换至英文输入状态"
      />
      <!-- <el-button plain size="small" icon="el-icon-minus" style="margin-left: 5px" @click="scanOrderDetail('8a822d8915cc49969447f618de8756d2')"
            >查询</el-button
          > -->
      <el-button plain size="small" icon="el-icon-minus" style="margin-left: 5px" @click="exceptionCarNumber">异常车牌过滤</el-button>
    </el-row>

    <el-row style="margin-top: 10px">
      <el-col :span="11">
        <el-card>
          <el-row>
            <el-input @change="table1FilterStrChange" v-model="table1FilterStr" style="width: 35%" size="small" placeholder="查询已选择数据" />
            <el-button plain size="small" icon="el-icon-delete" style="margin-left: 5px" @click="leftTableBatchDel">批量删除</el-button>
          </el-row>

          <el-row style="margin-top: 5px">
            <vxe-table ref="leftTable" height="400" :data="tableData1" :checkbox-config="{ trigger: 'row' }">
              <vxe-table-column type="checkbox" width="40"></vxe-table-column>
              <vxe-table-column type="seq" title="序号" width="50"></vxe-table-column>
              <vxe-table-column field="orderNo" title="工单编号" show-overflow>
                <template #default="{ row }">
                  {{ row.orderNo }}
                  <div class="copy-content" @click.stop="copyText(row.orderNo)">
                    <i class="vxe-icon--zoomout write-color" />
                  </div>
                </template>
              </vxe-table-column>
              <vxe-table-column field="exception" title="异常原因" show-overflow>
                <template #default="{ row }">
                  <span style="color: red">{{ row.exception }}</span>
                  <div class="copy-content" @click.stop="copyText(row.orderNo)">
                    <i class="vxe-icon--zoomout write-color" />
                  </div>
                </template>
              </vxe-table-column>
              <vxe-table-column title="操作">
                <template v-slot="{ row }">
                  <span style="color: #409eff; padding-right: 5px; cursor: pointer" @click="toDetailHandle(row)">详情</span>
                  <span style="color: #409eff; padding-left: 5px; cursor: pointer" @click="delRowFromLeftTableHandle(row)">删除</span>
                </template>
              </vxe-table-column>
            </vxe-table>
          </el-row>
        </el-card>
      </el-col>
      <el-col :span="2">
        <div style="text-align: center; margin-top: 200px">
          <el-button size="small" type="primary" icon="el-icon-arrow-right" @click="pushRowsToRight" circle />
          <br />
          <el-button size="small" style="margin-top: 15px" plain icon="el-icon-arrow-left" @click="pushRowsToLeft" circle />
        </div>
      </el-col>
      <el-col :span="11">
        <el-card>
          <el-row>
            <el-input @change="table2FilterStrChange" v-model="table2FilterStr" style="width: 35%" size="small" placeholder="查询已选择数据" />
            <el-button plain size="small" icon="el-icon-delete" style="margin-left: 5px" @click="rightTableBatchDel">批量删除</el-button>
            <el-button plain size="small" icon="el-icon-download" style="margin-left: 5px" @click="exportTableData">导出列表</el-button>
          </el-row>
          <el-row style="margin-top: 5px">
            <vxe-table ref="rightTable" height="400" :data="tableData2" :checkbox-config="{ trigger: 'row' }">
              <vxe-table-column type="checkbox" width="40"></vxe-table-column>
              <vxe-table-column type="seq" title="序号" width="50"></vxe-table-column>
              <vxe-table-column field="orderNo" title="工单编号" show-overflow>
                <template #default="{ row }">
                  {{ row.orderNo }}
                  <div class="copy-content" @click.stop="copyText(row.orderNo)">
                    <i class="vxe-icon--zoomout write-color" />
                  </div>
                </template>
              </vxe-table-column>
              <vxe-table-column field="plateNumber" title="车牌号码" show-overflow>
                <template #default="{ row }">
                  {{ row.plateNumber }}
                  <div class="copy-content" @click.stop="copyText(row.plateNumber)">
                    <i class="vxe-icon--zoomout write-color" />
                  </div>
                </template>
              </vxe-table-column>
              <vxe-table-column title="操作">
                <template v-slot="{ row }">
                  <span style="color: #409eff; padding-right: 5px; cursor: pointer" @click="toDetailHandle(row)">详情</span>
                  <span style="color: #409eff; padding-left: 5px; cursor: pointer" @click="delRowFromRightTableHandle(row)">删除</span>
                </template>
              </vxe-table-column>
            </vxe-table>
          </el-row>
        </el-card>
      </el-col>
    </el-row>
    <el-form ref="formData" :model="formData" :rules="rules">
      <el-row style="margin-top: 10px">
        <div style="float: left; width: 50%">
          <el-col :span="12">
            <el-form-item label="结算操作人:" label-width="100px" prop="operatorId">
              <el-select v-model="formData.operatorId" size="small" style="width: 80%">
                <el-option v-for="(item, index) in operatorList" :key="index" :label="item.name" :value="item.id"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="结算对象:" label-width="100px" prop="settler">
              <el-input v-model="formData.settler" size="small" style="width: 80%" />
            </el-form-item>
          </el-col>
        </div>
        <div style="float: right">
          <span style="margin-right: 20px; color: red; cursor: pointer" @click="clearTable2">清空列表</span>
          <span style="margin-right: 20px"><el-button @click="confirmSettleHandle" size="small" type="primary">确认结算</el-button></span>
        </div>
      </el-row>
    </el-form>
    <el-dialog title="车牌过滤" :visible.sync="dialogVisible" width="30%">
      <span>过滤的车牌扫码后会进入异常过滤列表</span>
      <el-form style="margin-top: 5px">
        <el-form-item>
          <el-input
            clearable
            v-model="exceptionCarPlatnumer"
            placeholder="请输入完整的车牌号码(如：浙A3WL12),多个车牌号码用英文逗号隔开"
            :rows="4"
            type="textarea"
            size="small"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" style="text-align: center">
        <el-button size="small" type="primary" plain @click="dialogVisible = false">取消</el-button>
        <el-button size="small" type="primary" @click="saveExceptionCarPlatnumber">提交</el-button>
      </div>
    </el-dialog>
    <el-drawer :visible.sync="orderDetailVisible" direction="rtl" size="80%">
      <div style="line-height: 40px; color: #000; font-weight: bold" slot="title">
        {{ detailTitle }}
      </div>
      <project-order-detail v-if="detailRow !== null" :detailRow="detailRow" />
    </el-drawer>
  </div>
</template>
<script>
import { orderDetail, orderDetailByOrderNo, orderProjectSettle, orderMuckSettle, getWorkBossByPhone } from '@/api/order'
import ProjectOrderDetail from '../../project/projectOrderDetail'
import XEClipboard from 'xe-clipboard'
export default {
  components: {
    ProjectOrderDetail
  },
  props: {
    activeName: {
      type: String,
      default: 'project'
    }
  },
  data() {
    return {
      dialogVisible: false,
      orderDetailVisible: false,
      detailTitle: '',
      detailRow: null,
      operater: this.$store.getters.realName,
      settleOperator: '',
      tableData1: [],
      tableData1Bak: [], // 异常工单数据备份
      tableData2: [],
      tableData2Bak: [], // 正常工单数据备份
      table1FilterStr: '', // 异常工单过滤字符串
      table2FilterStr: '', // 正常工单过滤字符串
      exceptionCarPlatnumer: '', //异常车牌过滤(字符串)
      exceptionCarPlatnumerList: [], //  异常车牌过滤列表
      operatorList: [], // 操作员列表
      formData: {
        operatorId: '',
        orderNos: '',
        settler: ''
      },
      rules: {
        operatorId: [{ required: true, message: '请选择结算操作人', trigger: 'blur' }],
        settler: [{ required: true, message: '请输入结算对象', trigger: 'blur' }]
      },
      codeValue: '',
      code: '',
      lastTime: '',
      nextTime: '',
      lastCode: '',
      nextCode: '',
      lsExceptionCarPlatnumerKey: '' // 异常车牌本地存储key
    }
  },
  created() {
    // 监听按键
    window.document.onkeypress = e => {
      if (window.event) {
        // IE
        this.nextCode = e.keyCode
      } else if (e.which) {
        // Netscape/Firefox/Opera
        this.nextCode = e.which
      }

      if (e.which === 13) {
        // 键盘回车事件
        if (this.code.length < 3) return // 扫码枪的速度很快，手动输入的时间不会让code的长度大于2，所以这里不会对扫码枪有效
        // console.log('扫码结束。')
        // console.log('条形码：', this.code)
        // console.log('code', this.code)
        // if (this.code.length === 20) {
        this.scanOrderDetail(this.code) // 获取到扫码枪输入的内容
        // }
        this.lastCode = ''
        this.lastTime = ''
        return
      }

      this.nextTime = new Date().getTime()
      if (!this.lastTime && !this.lastCode) {
        this.code = '' // 清空上次的条形码
        this.code += e.key
        // console.log('扫码开始---', this.code)
      }
      if (this.lastCode && this.lastTime && this.nextTime - this.lastTime > 500) {
        // 当扫码前有keypress事件时,防止首字缺失
        this.code = e.key
        // console.log('防止首字缺失。。。', this.code)
      } else if (this.lastCode && this.lastTime) {
        this.code += e.key
        if (this.code.length === 20) {
          this.scanOrderDetail(this.code) // 获取到扫码枪输入的内容
        }
        // console.log('扫码中。。。', this.code)
      }
      this.lastCode = this.nextCode
      this.lastTime = this.nextTime
    }
    if (this.activeName === 'project') {
      this.lsExceptionCarPlatnumerKey = 'ProjectExceptionCarPlatnumer'
    }
    if (this.activeName === 'muck') {
      this.lsExceptionCarPlatnumerKey = 'MuckExceptionCarPlatnumer'
    }
    this.getExceptionCarPlatnumber()
    this.getWorkBossByPhone()
  },
  methods: {
    scanOrderChange() {
      if (this.codeValue) {
        this.scanOrderDetail(this.codeValue)
      }
    },
    // 获取操作人
    getWorkBossByPhone() {
      let params = {
        phone: this.$store.getters.phone
      }
      getWorkBossByPhone(params).then(res => {
        this.operatorList = res.data
      })
    },
    // 确认结算
    confirmSettleHandle() {
      this.$refs.formData.validate(valid => {
        if (valid) {
          // 1.获取正常工单号放入结算表单中
          let arrOrderNos = []
          let checkedRecords = this.$refs.rightTable.getCheckboxRecords(true)
          if (checkedRecords.length === 0) {
            this.$alert('请先选择需要结算的工单!', '提示', {
              confirmButtonText: '关闭',
              type: 'error'
            })
            return
          }
          checkedRecords.forEach(item => {
            arrOrderNos.push(item.orderNo)
          })
          this.formData.orderNos = arrOrderNos.join(',')
          // 2.提交数据
          this.$confirm(`是否确定已选中的${arrOrderNos.length}条工单?`, '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          })
            .then(() => {
              if (this.activeName === 'project') {
                orderProjectSettle(this.formData).then(res => {
                  if (res.code === 200) {
                    this.$message.success('结算成功!')
                    this.tableData2 = []
                    this.tableData2Bak = []
                    this.formData.orderNos = ''
                  } else {
                    this.$message.error(res.msg)
                  }
                })
              } else {
                orderMuckSettle(this.formData).then(res => {
                  if (res.code === 200) {
                    this.$message.success('结算成功!')
                    this.tableData2 = []
                    this.tableData2Bak = []
                    this.formData.orderNos = ''
                  } else {
                    this.$message.error(res.msg)
                  }
                })
              }
            })
            .catch(() => {
              this.$message({
                type: 'info',
                message: '已取消'
              })
            })
        } else {
          return
        }
      })
    },
    // 异常工单已有数据查询筛选
    table1FilterStrChange(v) {
      if (v.trim()) {
        this.tableData1.forEach(item => {
          let filterResult = []
          // 筛选
          if (item.orderNo.indexOf(v.trim()) >= 0 || item.exception.indexOf(v.trim()) >= 0 || item.plateNumber.indexOf(v.trim()) >= 0) {
            filterResult.push(item)
          }
          // 如果存在筛选结果
          if (filterResult.length > 0) {
            // 1.备份原表数据
            this.tableData1Bak = this.tableData1
            // 2.重新赋值异常工单表格数据为筛选的结果
            this.tableData1 = filterResult
          }
        })
      }
      // 当筛选字符串为空时 还原备份的表格数据
      else {
        this.tableData1 = this.tableData1Bak
      }
    },
    // 正常工单已有数据查询筛选
    table2FilterStrChange(v) {
      if (v.trim()) {
        this.tableData2.forEach(item => {
          let filterResult = []
          // 筛选
          if (item.orderNo.indexOf(v.trim()) >= 0 || item.plateNumber.indexOf(v.trim()) >= 0) {
            filterResult.push(item)
          }
          // 如果存在筛选结果
          if (filterResult.length > 0) {
            // 1.备份原表数据
            this.tableData2Bak = this.tableData2
            // 2.重新赋值异常工单表格数据为筛选的结果
            this.tableData2 = filterResult
          }
        })
      }
      // 当筛选字符串为空时 还原备份的表格数据
      else {
        this.tableData2 = this.tableData2Bak
      }
    },
    // 扫描的工单
    scanOrderDetail(orderNo) {
      if (orderNo.length === 20) {
        orderDetailByOrderNo({ orderNo: orderNo }).then(res => {
          if (res.code === 200) {

            // 如果该工单已删除 提示不可进行结算操作即可
            if(res.data.deleteFlag==='DELETED') {
              this.$message.error('该工单已删除，不允许操作结算')
              return
            }

            // 如果工单已结算，提示
            if (this.activeName === 'muck') {
              if (res.data.muckSettlementStatus === 'SETTLE') {
                this.$message.error('该工单已完成渣土场结算，不能重复结算')
                this.codeValue = '' //值空
                return
              }
            }

            if (this.activeName === 'project') {
              if (res.data.workSettlementStatus === 'SETTLE') {
                this.$message.error('该工单已完成工地结算，不能重复结算')
                this.codeValue = '' //值空
                return
              }
            }

            let order = {
              id: res.data.id,
              orderNo: res.data.orderNo,
              exception: '',
              plateNumber: res.data.plateNumber
            }

            this.$message.success('工单获取成功')
            // 判断是否异常 异常工单
            if (res.data.isException === 'TRUE') {
              // 放入左边表格
              let index = this.tableData1.findIndex(item => {
                return item.id === res.data.id
              })
              // console.log(index)
              if (index == -1) {
                order.exception = res.data.exception
                this.tableData1.push(order)
              }
              return
            }
            // 查看车牌是否在异常车牌过滤列表中
            else if (this.exceptionCarPlatnumerList.includes(res.data.plateNumber)) {
              let index = this.tableData1.findIndex(item => {
                return item.id === res.data.id
              })
              if (index == -1) {
                order.exception = '异常车牌'
                this.tableData1.push(order)
              }
              return
            }
            // 放入正常的工单列表
            else {
              let index = this.tableData2.findIndex(item => {
                return item.id === res.data.id
              })
              // console.log(index)
              if (index == -1) {
                this.tableData2.push(order)
              }
            }
          } else {
            this.$message.error(res.msg)
          }
          this.codeValue = '' //值空
        })
      }
    },
    // 保存异常车票
    saveExceptionCarPlatnumber() {
      localStorage.setItem(this.lsExceptionCarPlatnumerKey, this.exceptionCarPlatnumer)
      this.getExceptionCarPlatnumber()
      this.dialogVisible = false
    },
    // 获取异常车牌
    getExceptionCarPlatnumber() {
      this.exceptionCarPlatnumer = localStorage.getItem(this.lsExceptionCarPlatnumerKey)
      if (this.exceptionCarPlatnumer) {
        this.exceptionCarPlatnumerList = this.exceptionCarPlatnumer.split(',')
      }
    },
    // 添加/修改异常车牌过滤
    exceptionCarNumber() {
      this.dialogVisible = true
    },
    // 从左边表中删除一行
    delRowFromLeftTableHandle(row) {
      this.$confirm('是否确定删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          let index = this.tableData1.findIndex(item => item.id === row.id)
          let bakIndex = this.tableData1Bak.findIndex(item => item.id === row.id)
          this.tableData1Bak.splice(bakIndex, 1)
          this.tableData1.splice(index, 1)
          // 还原备份表格剩余数据
          if (this.tableData1.length === 0) {
            this.tableData1 = this.tableData1Bak
          }
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消'
          })
        })
    },
    // 右边表格删除选中行
    delRowFromRightTableHandle(row) {
      this.$confirm('是否确定删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          let index = this.tableData2.findIndex(item => item.id === row.id)
          let bakIndex = this.tableData2Bak.findIndex(item => item.id === row.id)
          this.tableData2Bak.splice(bakIndex, 1)
          this.tableData2.splice(index, 1)
          // 还原备份表格剩余数据
          if (this.tableData2.length === 0) {
            this.tableData2 = this.tableData2Bak
          }
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消'
          })
        })
    },
    // 左边表格批量删除
    leftTableBatchDel() {
      let checkedRecords = this.$refs.leftTable.getCheckboxRecords(true)
      if (checkedRecords.length === 0) {
        return
      }
      this.$confirm('是否确定删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          checkedRecords.forEach(item => {
            let index = this.tableData1.findIndex(row => item.id === row.id)
            this.tableData1.splice(index, 1)
            let bakIndex = this.tableData1Bak.findIndex(item => item.id === row.id)
            this.tableData1Bak.splice(bakIndex, 1)
          })
          // 还原备份表格剩余数据
          if (this.tableData1.length === 0) {
            this.tableData1 = this.tableData1Bak
          }
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消'
          })
        })
    },
    // 右边表格批量删除
    rightTableBatchDel() {
      let checkedRecords = this.$refs.rightTable.getCheckboxRecords(true)
      if (checkedRecords.length === 0) {
        return
      }
      this.$confirm('是否确定删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          checkedRecords.forEach(item => {
            let index = this.tableData2.findIndex(row => item.orderNo === row.orderNo)
            this.tableData2.splice(index, 1)
            let bakIndex = this.tableData2Bak.findIndex(item => item.id === row.id)
            this.tableData2Bak.splice(bakIndex, 1)
          })
          // 还原备份表格剩余数据
          if (this.tableData2.length === 0) {
            this.tableData2 = this.tableData2Bak
          }
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消'
          })
        })
    },
    // 将左边选中的数据放到右边表格
    pushRowsToRight() {
      let checkedRecords = this.$refs.leftTable.getCheckboxRecords(true)
      if (checkedRecords.length > 0) {
        this.tableData2 = this.tableData2.concat(checkedRecords)
        // 将左边表中的数据移除
        checkedRecords.forEach(item => {
          let index = this.tableData1.findIndex(row => {
            return item.orderNo === row.orderNo
          })
          this.tableData1.splice(index, 1)
        })
      }
    },
    // 将右边选中的数据放到左边表格
    pushRowsToLeft() {
      let checkedRecords = this.$refs.rightTable.getCheckboxRecords(true)
      if (checkedRecords.length > 0) {
        this.tableData1 = this.tableData1.concat(checkedRecords)
        // 将左边表中的数据移除
        checkedRecords.forEach(item => {
          let index = this.tableData2.findIndex(row => {
            return item.orderNo === row.orderNo
          })
          this.tableData2.splice(index, 1)
        })
      }
    },
    // 清空右边表格
    clearTable2() {
      this.$confirm('是否确定清空列表?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.tableData2 = []
          this.tableData2Bak = []
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消'
          })
        })
    },
    exportTableData() {
      this.$refs.rightTable.exportData({ filename: '结算工单列表', type: 'csv' })
    },

    // 查看工地详情
    toDetailHandle(row) {
      orderDetail({ id: row.id }).then(res => {
        this.detailRow = res.data
        this.detailTitle = res.data.orderNo + '——详情'
        this.orderDetailVisible = true
      })
    },
    copyText(value, type) {
      XEClipboard.copy(value)
      this.$message.success('已复制到剪切板')
    }
  }
}
</script>
<style scoped>
.el-dialog__body {
  padding: 10px 20px;
  color: #606266;
  font-size: 14px;
  word-break: break-all;
}
::v-deep .el-form-item__label {
  color: #409eff !important;
}
/*单号复制样式 开始*/
.copy-content {
  right: 0.1em;
  top: 0.1em;
  float: right !important;
  position: absolute !important;
}
.copy-content:hover {
  background-color: #89bdf1;
  float: right !important;
  height: 1.8em;
  width: 1.8em;
}
</style>
