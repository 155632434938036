<template>
  <div style="background-color: rgba(242, 242, 242, 1)">
    <div style="margin: 0px 20px 20px 20px; background-color: #fff">
      <el-card>
        <el-tabs v-model="activeName">
          <el-tab-pane name="project" label="工地扫码结算">
            <settle-module :activeName="'project'" />
          </el-tab-pane>
          <el-tab-pane name="muck" label="渣土场扫描结算">
            <settle-module :activeName="'muck'" />
          </el-tab-pane>
        </el-tabs>
      </el-card>
    </div>
  </div>
</template>
<script>
import SettleModule from './components/settleModule'
export default {
  components: {
    SettleModule
  },
  data() {
    return {
      activeName: 'project'
    }
  }
}
</script>
